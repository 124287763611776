import React from "react";
import "./style.css";

import GrayPhoneIcon from "@assets/course/grayPhoneIcon.png";

export default (props) => (
  <div className="callView">
    <button onClick={() => props.handleCall()} className="callBtn w-100">
      <img src={GrayPhoneIcon} alt="phoneIcon" />
      <p>
        Call <span style={{ color: "#8B8B8B" }}>+91</span> 98220-8-11-33
      </p>
    </button>
  </div>
);
