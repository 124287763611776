import React, { useEffect, useState } from "react";
import BSSFormView from "./view";

import { useDispatch, useSelector } from "react-redux";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";

import * as UserDashboardServices from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import * as TimelineRedirectActions from "@redux/actions/TimelineRedirect";
import * as QualificationsAction from "@redux/actions/Qualifications";

import { decryptText } from "@library/enc-dec";
import {
  checkFileExtension,
  isFileUploadAllowedAsPerSize,
} from "@library/helpers";

function BSSForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [listOfYearOfPassing, setListOfYearOfPassing] = useState([]);
  const listOfBloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const [iserror, setIserror] = useState(false);
  const user = useSelector((state) => state.dashboard.user);
  const timelineRedirectDetails = useSelector(
    (state) => state.timelineRedirect.timelineRedirectDetails
  );
  const bssQualifications = useSelector(
    (state) => state.qualifications.bssQualifications
  );
  const [savingLoading, setSavingLoading] = useState(false);

  useEffect(() => {
    const startYear = 1950;
    const currentYear = new Date().getFullYear();
    const yearArray = generateYearArray(startYear, currentYear);
    setListOfYearOfPassing(yearArray);
  }, []);

  useEffect(() => {
    if (!bssQualifications || !bssQualifications.success) {
      getAllBSSDegrees();
    }
  }, []);

  const getAllBSSDegrees = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const allBSSDegrees = await UserDashboardServices.getAllBSSDegrees(
        authToken
      );
      if (
        allBSSDegrees.success &&
        allBSSDegrees.data &&
        allBSSDegrees.data.length > 0
      ) {
        let values = {
          success: allBSSDegrees.success,
          qualifications: [],
        };
        allBSSDegrees.data.forEach((qualifications, index) => {
          values.qualifications.push(qualifications.title);
        });
        dispatch(QualificationsAction.updateBssQualifications(values));
      }
    } catch (err) {
      console.log("Error coming from getAllBSSDegrees()", err);
    }
  };

  const handleUserUpdate = async (key, value) => {
    try {
      setIserror(false);

      let finalValue = value;

      if (
        key === "aadhaarCard" ||
        key === "panCard" ||
        key === "signature" ||
        key === "otherDocs"
      ) {
        const payload = { fieldName: key, thumbnailUrl: value };
        let authToken = decryptText(localStorage.getItem("eMediat"));

        const uploadedFile = await UserDashboardServices.uploadUserDocuments(
          payload,
          authToken
        );

        finalValue = uploadedFile.success ? uploadedFile.data : value;
      }

      // debugger
      let updatedUser = produce(user, (draft) => {
        draft["bssFormDetails"][key] = finalValue;

        if (key === "guardianName") {
          draft["bssFormDetails"]["guardianNameStatus"] = "inProgress";
        } else if (key === "underGraduateDegree") {
          draft["bssFormDetails"]["underGraduateDegreeStatus"] = "inProgress";
        } else if (key === "underGraduateInstituteName") {
          draft["bssFormDetails"]["underGraduateInstituteNameStatus"] =
            "inProgress";
        } else if (key === "underGraduateInstituteAddress") {
          draft["bssFormDetails"]["underGraduateInstituteAddressStatus"] =
            "inProgress";
        } else if (key === "registrationNumber") {
          draft["bssFormDetails"]["registrationNumberStatus"] = "inProgress";
        } else if (key === "passingYear") {
          draft["bssFormDetails"]["passingYearStatus"] = "inProgress";
        } else if (key === "signature") {
          draft["bssFormDetails"]["signatureVerificationStatus"] = "inProgress";
        } else if (key === "aadhaarCard") {
          draft["bssFormDetails"]["aadhaarVerificationStatus"] = "inProgress";
        } else if (key === "panCard") {
          draft["bssFormDetails"]["panVerificationStatus"] = "inProgress";
        } else if (key === "otherDocs") {
          draft["bssFormDetails"]["otherDocsVerificationStatus"] = "inProgress";
        } else if (key === "placeOfSignature") {
          draft["bssFormDetails"]["placeOfSignatureStatus"] = "inProgress";
        } else if (key === "bssVerification") {
          draft["bssFormDetails"]["bssVerificationStatus"] = "inProgress";
        }
      });

      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log("BSS Form : Error coming from handleTextInput()", err);
    }
  };

  const handleInputChange = (event, inputAction) => {
    try {
      let eventType = event.target.type;
      let inputValue = event.target.value;
      if (eventType === "checkbox") {
        inputValue = event.target.checked;
        handleUserUpdate("bssAcknowledged", inputValue);
      } else {
        handleUserUpdate(inputAction, inputValue);
      }
    } catch (err) {
      console.log("BSS Form : Error coming from handleInputChange()", err);
    }
  };

  const handleFileUpload = (attachId) => {
    try {
      let fileType = attachId.split("_")[0];
      // debugger
      if (document.getElementById(fileType)) {
        document.getElementById(fileType).click();
        document
          .getElementById(fileType)
          .addEventListener("change", function (event) {
            let validFile = checkFileExtension(event);
            if (validFile) {
              let fileName = this.value.replace(/C:\\fakepath\\/i, "");
              const file = event.target.files[0];

              if (!isFileUploadAllowedAsPerSize(file)) {
                alert("File size exceeds 1 MB. Please upload a smaller file.");
                return;
              }

              handleUserUpdate(fileType, file);
            } else {
              alert("Please upload only images");
            }
          });
      } else {
        alert("Some issue please refresh the page");
      }
    } catch (err) {
      console.log("Identity Info : Error coming from handleFileUpload()", err);
    }
  };

  const handleSave = async () => {
    try {
      const { aadhaarCard, panCard, signature } = user?.bssFormDetails;

      const payload = {
        ...user.bssFormDetails,
        bssVerificationStatus:
          user.bssFormDetails.bssVerificationStatus === "Rejected"
            ? "unVerified"
            : user.bssFormDetails.bssVerificationStatus,
      };

      if (!aadhaarCard || !panCard || !signature) {
        setIserror(true);
        return;
      }

      setSavingLoading(true);

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await UserDashboardServices.updateBssForm(
        payload,
        authToken
      );

      if (response.success) {
        alert(response.message);
        setIserror(false);
        dispatch(
          UserDashboardActions.updateUserDetails({
            ...user,
            bssFormDetails: response.data,
          })
        );
        setSavingLoading(false);
        if (
          timelineRedirectDetails &&
          timelineRedirectDetails.purchaseId &&
          timelineRedirectDetails.purchaseId !== "" &&
          timelineRedirectDetails.section === "bssDetails"
        ) {
          navigate(`/timeline/${timelineRedirectDetails.purchaseId}`);
        } else {
          dispatch(TimelineRedirectActions.resetTimelineRedirectReducers());
        }
        return;
      }
      setSavingLoading(false);
      alert(response.message);
    } catch (err) {
      console.log("Legal Info: Error coming from handleSave()", err);
    }
  };

  const generateYearArray = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  return (
    <>
      {user && (
        <BSSFormView
          iserror={iserror}
          userData={user}
          handleInputChange={handleInputChange}
          bssQualifications={
            bssQualifications && bssQualifications.qualifications
              ? bssQualifications.qualifications
              : []
          }
          listOfYearOfPassing={listOfYearOfPassing}
          listOfBloodGroups={listOfBloodGroups}
          handleFileUpload={handleFileUpload}
          handleSave={handleSave}
          savingLoading={savingLoading}
        />
      )}
    </>
  );
}

export default BSSForm;
