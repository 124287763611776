import React, { useState, useEffect, useRef } from "react";

import CountryCodeFlagPhoneNoView from "./view";

import DownBlackDropDownIcon from "@assets/timeline/icons/downBlackDropDownIcon.png";
import { countries } from "countries-list";

function CountryCodeFlagPhoneNo(props) {
  // Ref : https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/ TODO : put all svg from cdn to our git
  const countryImageImgStaticUrl =
    "https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/";
  const countryImageImgStaticExtention = ".svg";
  const [options, setOptions] = useState([]);
  const [phoneNo, setPhoneNo] = useState(0);
  const defaultCountryCode = "+91";
  const [selectedOption, setSelectedOption] = useState({
    value: defaultCountryCode,
    image: countryImageImgStaticUrl + "in" + countryImageImgStaticExtention,
  });
  const phoneNoInputBox = useRef(null);

  useEffect(() => {
    if (props.fullNameSetter === null) {
      if (phoneNoInputBox.current) {
        phoneNoInputBox.current.focus();
      }
    }
  });

  useEffect(() => {
    createDropDownOptions();
    handleStyleFixesForReactSelectLib();
  }, []);

  function createDropDownOptions() {
    let tempOptions = [];
    Object.entries(countries).map(([countryKey, countryData]) => {
      let formatedCountryCode = "+" + countryData.phone[0];
      if (props.countryCode && props.countryCode === formatedCountryCode) {
        setSelectedOption({
          value: formatedCountryCode,
          image:
            countryImageImgStaticUrl +
            countryKey.toLocaleLowerCase() +
            countryImageImgStaticExtention,
        });
      }
      let optionData = {
        value: formatedCountryCode,
        image:
          countryImageImgStaticUrl +
          countryKey.toLocaleLowerCase() +
          countryImageImgStaticExtention,
      };

      tempOptions.push(optionData);
    });
    setOptions(tempOptions);
    props.getterSetter("countryCode", defaultCountryCode);
  }

  const handleCountryChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    props.getterSetter("countryCode", selectedOption.value);
  };
  const handlePhoneNo = (event) => {
    if (event.target.value) {
      let value = event.target.value;
      setPhoneNo(value);
      props.getterSetter("phoneNo", value);
    }
  };
  const handleKeyDownOnPhoneNo = (event) => {
    if (event.key === "Enter" && props.handleIamIntrested) {
      props.handleIamIntrested(); // defined in @pages\Course\Sections\Registration\index.js
    }
  };

  const handleStyleFixesForReactSelectLib = () => {
    let indicatorsContainer = document.querySelectorAll(
      ".css-1hb7zxy-IndicatorsContainer"
    );
    if (indicatorsContainer.length) {
      indicatorsContainer = indicatorsContainer[0];
      let newHtml =
        "<img class='countryCodeFlagDropDownImg' src='" +
        DownBlackDropDownIcon +
        "' alt='countryCodeFlagDropDownImg'/>";
      indicatorsContainer.innerHTML = newHtml;
    }
  };

  return (
    <>
      <CountryCodeFlagPhoneNoView
        options={options}
        handleCountryChange={handleCountryChange}
        selectedOption={selectedOption}
        handlePhoneNo={handlePhoneNo}
        phoneNo={phoneNo}
        validationError={props.validationError}
        phoneNoInputBox={phoneNoInputBox}
        handleKeyDownOnPhoneNo={handleKeyDownOnPhoneNo}
      />
    </>
  );
}

export default CountryCodeFlagPhoneNo;
